<template>
  <div class="card">
    <a-page-header
      :title="document.title"
      @back="() => $router.push('/degital_library')"
    >
    </a-page-header>
    <div class="py-3 px-5">
      <div class="document-details">
        <div class="docuemnt-image-wrapper">
          <img
            v-if="document.coverPicture"
            :src="document.coverPicture"
            alt="Document Image"
            class="document-image"
          />
          <img
            v-else
            src="../../../../public/resources/images/document.svg"
            alt="Document Image"
            class="document-icon"
          />
        </div>
        <div style="width: 70%">
          <p v-if="document.description">
            <strong>{{ $t("product.description") }}: </strong>
            <span v-html="document.description"></span>
          </p>
          <p v-if="document.createdBy && document.createdBy.name">
            <strong>{{ $t("library.createdBy") }}: </strong>
            <span class="mr-2">{{ document.createdBy.name }}</span>
            <a-tag
              :color="document.createdBy.role === 'admin' ? 'blue' : 'purple'"
            >
              {{
                document.createdBy.role === "admin"
                  ? $t("autorisation.admin")
                  : $t("emploi.enseignant")
              }}
            </a-tag>
          </p>
          <p>
            <strong>{{ $t("bulletin.matieres") }}: </strong>
            <a-tag v-for="subject in document.subject" :key="subject._id">
              {{ subject.name }}
            </a-tag>
          </p>
          <p>
            <strong>{{ $t("actualite.levels") }}: </strong>
            <a-tag v-for="levelValue in document.level" :key="levelValue">{{
              $t(getLabelByValue(levelValue))
            }}</a-tag>
          </p>
        </div>
      </div>
      <h4 class="mt-5 mb-4">{{ $t("library.ressources") }}</h4>
      <a-table :columns="columns" :data-source="files" :rowKey="'fileUrl'">
        <template slot="fileName" slot-scope="text, record">
          <img
            :src="
              require(`../../../../public/resources/images/${getFileTypeIcon(
                record.fileType
              )}`)
            "
            alt="File Type Icon"
            style="width: 25px; margin-right: 10px"
          />
          <span>{{ record.fileName + "." + record.fileType }}</span>
        </template>
        <template slot="actions" slot-scope="text, record">
          <a-button
            type="primary"
            icon="download"
            @click="downloadFile(record.fileUrl)"
          />
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import apiClient from "@/services/axios";
export default {
  name: "DocumentDetails",
  data() {
    return {
      document: {},
      files: [],
      levels: [
        { value: "-2", label: "niveau.niveauPre" },
        { value: "-1", label: "niveau.niveau0" },
        { value: "1", label: "niveau.niveau1" },
        { value: "2", label: "niveau.niveau2" },
        { value: "3", label: "niveau.niveau3" },
        { value: "4", label: "niveau.niveau4" },
        { value: "5", label: "niveau.niveau5" },
        { value: "6", label: "niveau.niveau6" },
        { value: "7", label: "niveau.niveau7" },
        { value: "8", label: "niveau.niveau8" },
        { value: "9", label: "niveau.niveau9" },
        { value: "10", label: "niveau.niveau10" },
        { value: "11", label: "niveau.niveau11" },
        { value: "12", label: "niveau.niveau12" },
        { value: "13", label: "niveau.niveau13" },
      ],
    };
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t("library.fileName"),
          dataIndex: "fileName",
          key: "fileName",
          scopedSlots: { customRender: "fileName" },
        },
        {
          title: this.$t("action.actions"),
          key: "actions",
          scopedSlots: { customRender: "actions" },
          fixed: "right",
        },
      ];
    },
  },
  created() {
    this.getDocument();
  },
  methods: {
    getDocument() {
      const id = this.$route.params.id;
      apiClient
        .get(`library/item/${id}`)
        .then((res) => {
          this.document = res.data;
          this.files = this.document.files.map((fileUrl) => {
            return this.extractFileNameAndType(fileUrl);
          });
        })
        .catch((e) => {})
        .finally(() => {});
    },
    extractFileNameAndType(url) {
      const parts = url.split("/");
      const fileNameWithType = parts[parts.length - 1];
      const decodedFileNameWithType = decodeURIComponent(fileNameWithType);
      const regex = /^(\d{13})-(.+)$/; 
      const match = decodedFileNameWithType.match(regex);
      const extractedName = match ? match[2] : decodedFileNameWithType;

      const lastDotIndex = extractedName.lastIndexOf(".");
      const fileName =
        lastDotIndex !== -1
          ? extractedName.slice(0, lastDotIndex)
          : extractedName;
      const fileType =
        lastDotIndex !== -1 ? extractedName.slice(lastDotIndex + 1) : "";

      return { fileUrl: url, fileName, fileType };
    },
    getFileTypeIcon(fileType) {
      switch (fileType.toLowerCase()) {
        case "pdf":
          return "pdf.png";
        case "docx":
        case "doc":
          return "word.png";
        case "jpg":
        case "jpeg":
        case "png":
          return "photo.png";
        case "xlsx":
        case "xls":
          return "excel.png";
        case "csv":
          return "csv.png";
        case "pptx":
        case "pptm":
          return "powerpoint.png";
        case "mp4":
        case "mkv":
        case "mov":
        case "avi":
          return "video.png";
        default:
          return "otherDocument.svg";
      }
    },
    downloadFile(fileUrl) {
      const fileType = this.getFileType(fileUrl);
      if (fileType === "pdf" || fileType === "image" || fileType === "video") {
        // Open the file in a new browser tab (preview)
        window.open(fileUrl, "_blank");
      } else {
        // For other file types, trigger a download
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = fileUrl.split("/").pop(); // Extracts the filename from the URL
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    getFileType(fileUrl) {
      const fileExtension = fileUrl.split(".").pop().toLowerCase();
      const knownFiles = {
        pdf: ["pdf"],
        image: ["png", "jpg", "jpeg"],
        video: ["mp4", "mkv", "mov", "avi"],
        word: ["doc", "docx"],
        excel: ["xlsx", "xls"],
        ppt: ["ppt", "pptx"],
        // Add other types as necessary
      };

      for (const [type, extensions] of Object.entries(knownFiles)) {
        if (extensions.includes(fileExtension)) {
          return type;
        }
      }
      return "unknown"; // Default return for unknown file types
    },
    getLabelByValue(value) {
      const level = this.levels.find((level) => level.value === String(value));
      return level ? level.label : value;
    },
  },
};
</script>

<style scoped>
.document-details {
  display: flex;
  gap: 20px;
}
.docuemnt-image-wrapper {
  width: 160px;
  height: 160px;
  border: 1px solid #adb5bd;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.document-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.document-icon {
  width: 50px;
  height: 50px;
}
</style>
